
import { extendTheme, theme as baseTheme } from "@chakra-ui/react"
import { mode } from '@chakra-ui/theme-tools'

const theme = {
    styles: {
        global: (props) => ({
            body: {
                fontFamily: 'body',
                color: mode('gray.800', 'whiteAlpha.900')(props),
                bg: mode('white', 'gray.800')(props),
                lineHeight: 'base',
            },
            '*::placeholder': {
                color: mode('gray.400', 'whiteAlpha.400')(props),
            },
            '*, *::before, &::after': {
                borderColor: mode('gray.200', 'whiteAlpha.300')(props),
                wordWrap: 'break-word',
            },
            a: {
                color: "red.800",
                _hover: {
                    color: "red",
                    textDecoration: "underline",
                    cursor: "pointer",
                }
            }
        }),


    },
    components: {
        Button: {
            defaultProps: {
                colorScheme: "red"
            },
        },
        Heading: {
            baseStyle: {
                textColor: "red.600"
            },
        },
    },
};

export default extendTheme(baseTheme, theme);